import React from 'react';


const Planejamento = () => {
    return (
        <>
            <div className="planejamento" id='planejamento'>

                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-[#227d93]">
                            {/* We build digital solutions to help businesses scale */}                                
                            Missão
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Proporcionar soluções ergonômicas inovadoras e eficazes, promovendo a saúde, segurança e bem-estar dos trabalhadores, melhorando a produtividade e garantindo conformidade com as normas regulamentadoras, especialmente a NR 17. Nosso compromisso é transformar ambientes de trabalho, tornando-os mais saudáveis e eficientes através da implementação de melhores práticas de ergonomia.</div>
                            Visão
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Ser a empresa líder em consultoria ergonômica no Brasil, reconhecida pela excelência na promoção de ambientes de trabalho saudáveis e seguros. Almejamos ser a referência em soluções ergonômicas que contribuem para a redução de acidentes e doenças ocupacionais, aumentando a qualidade de vida dos trabalhadores e a eficiência das organizações.</div>
                            Valores
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">Excelência, Inovação, Integridade, Saúde e Segurança, Sustentabilidade, Parceria e Comprometimento. Esses elementos irão orientar a atuação da sua empresa, refletindo o compromisso com a excelência, inovação, e o bem-estar dos trabalhadores.</div>
                            </h1>
                     </div>
                </div>
            </div>
        </>
    )
}

export default Planejamento;