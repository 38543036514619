import React from 'react';
import img from '../images/Web-developer.png';

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-1 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-1 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-1 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={img} />
                        </div>
                        <div className="flex-col my-0 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-[#227d93] font-bold">Equipe de consultores extremamente qualificados, com mais de 15 anos de experiência</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>A ErgoEng tem o compromisso de promover eficiência, eficácia e conforto dos trabalhadores no ambiente de Trabalho através de Consultoria em Ergonomia com foco na promoção da saúde, prevenção e controle dos riscos ergonômicos. Impactando diretamente na melhoria da produtividade, redução de doenças ocupacionais e afastamentos decorrentes dos riscos ergonômicos, promovendo maior qualidade de vida, saúde e segurança de nossos clientes.</p>
                            </div>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>Oferecemos soluções completas em Ergonomia – Análise Ergonômica do Trabalho, Avaliação Ergonômica Preliminar, Treinamentos, Palestras, Gestão de Riscos Ergonômicos, Estruturação, treinamento e acompanhamento do Comitê de Ergonomia (COERGO), dentre outras soluções.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;