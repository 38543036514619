import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../images/logo1.png';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30" id="footer">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                    {/* 1st block */}
                    <div className="col-span-12 lg:col-span-6">
                        <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                            <img alt="img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={logo} />
                            <div className='text-md my-2 font-medium text-gray-600'>
                                <h5><b>E-mail: </b>ergoengconsultoria@gmail.com</h5>
                            </div>
                            <div className='text-md my-2 font-medium text-gray-600'>
                                <h5><b>Tel.: </b>61-99267-3037</h5>
                            </div>
                            <div className='text-md my-2 font-medium text-gray-600'>
                                <h5><b>Brasília/DF</b></h5>
                            </div>
                            
                        </div>
                    </div>

                     {/* 2nd block */}
                    <div className="col-span-6 md:col-span-6 lg:col-span-6 mx-auto">
                        <h6 className="text-[#227d93] text-xl font-bold mb-4">Nossos Serviços</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <Link to="#" className="text-[#227d93] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Consultoria para Conformidade Regulamentar</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#227d93] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Gestão de riscos ergonômicos</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#227d93] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Treinamento e capacitação</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#227d93] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Parecer/Laudo ergonômico</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#227d93] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Ergonomia com foco na Agricultura</Link>
                        </li>
                        
                        <li className="mb-2">
                            <Link to="#" className="text-[#227d93] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Demais Serviços</Link>
                        </li>
                        </ul>
                    </div>

                </div>

                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                    <div className="text-sm text-gray-200 font-semibold py-1">
                    Copyright &copy; {new Date().getFullYear()}{"  "}
                    <HashLink
                        to="#"
                        className=" hover:text-gray-900"
                    >
                        
                    </HashLink>.  Todos os direitos reservados.
                    </div>
                </div>
                </div>

                </div>
                
            </footer>
        </>
    )
}
export default Footer;
