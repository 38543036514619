import React from 'react';
import img from '../images/web.png'; 
import img2 from '../images/web2.jpeg';
import img3 from '../images/web3.jpeg';
import img4 from '../images/web4.jpeg';
import img5 from '../images/web5.jpeg';
import img6 from '../images/web6.jpeg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down" className="mt-4">
                    <div className="my-3 py-3">
                        <h2 className="my-2 text-center text-3xl text-[#227d93] uppercase font-bold">Nossos Serviços</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-[#227d93]">Somos extremamente comprometidos com o crescimento e o sucesso dos nossos clientes.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Consultoria para Conformidade Regulamentar</h2>
                                    <p className="text-md font-medium">
                                        - Auxílio na adequação às exigências da NR 17 e outras normas regulamentadoras, garantindo que a empresa esteja em conformidade legal.
                                    </p>
                                    <p className="text-md font-medium">
                                        - Elaboração e revisão de documentos regulatórios, como PGRTRs (Programas de Gerenciamento de Riscos) no que se refere a ergonomia e acompanhamento do GRO.
                                    </p>
                                    <p className="text-md font-medium">
                                        - Análise Ergonômica do Trabalho – AET e Avaliação Ergonômica Preliminar – AEP
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Gestão de riscos ergonômicos </h2>
                                    <p className="text-md font-medium">
                                        - Implementação de sistemas de monitoramento contínuo das condições ergonômicas no ambiente de trabalho.
                                    </p>
                                    <p className="text-md font-medium">
                                        - Avaliações periódicas para garantir a manutenção das melhorias implementadas e identificar novas necessidades.
                                    </p>
                                    <p className="text-md font-medium">
                                        - Implantação e acompanhamento do comitê de ergonomia.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Treinamento e capacitação</h2>
                                    <p className="text-md font-medium">
                                        - Programas de treinamento para trabalhadores e gestores sobre práticas ergonômicas, prevenção de lesões e utilização correta dos equipamentos. Workshops e palestras.
                                    </p>
                                </div>
                            </div>           
                        </div>
                    </div>
            </section>
            <br></br>
            <section data-aos="zoom-in-down">
                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t  group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img5} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Parecer/Laudo ergonômico</h2>
                                    <p className="text-md font-medium">
                                        - Documento que especifica as normas de segurança ergonômica para setores pontuais que por algum motivo foram solicitadas, por queixas dos trabalhadores, solicitação do Ministério Público, solicitação por uma demanda trabalhista, mudanças que ocorreram em um determinado setor… etc.
                                        
                                    </p>
                                    <p className="text-md font-medium">
                                        - Exemplo – Parecer de Luminosidade, alturas de bancadas, escolha de cadeiras, etc.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img6} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Ergonomia com foco na Agricultura</h2>
                                    <p className="text-md font-medium">
                                        - Adaptação de ferramentas e equipamentos agrícolas para melhorar a ergonomia dos trabalhadores rurais.
                                    </p>
                                    <p className="text-md font-medium">
                                        - Desenvolvimento de soluções ergonômicas específicas para atividades de plantio, colheita e processamento agrícola.
                                    </p>
                                </div>
                            </div> 

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Demais Serviços</h2>
                                    <p className="text-md font-medium">
                                        - Estabelecimento de nexo causal.
                                    </p>
                                    <p className="text-md font-medium">
                                        - Avaliação de Funcionários (isoladamente – orientações, restrição de atividades, etc).
                                    </p>
                                    <p className="text-md font-medium">
                                        - Análise do uso e funcionalidade de Produtos (parecer sobre melhoria e produtos).
                                    </p>
                                    <p className="text-md font-medium">
                                        - Projeto e Redesign de Postos de Trabalho.
                                    </p>
                                    <p className="text-md font-medium">
                                    - Elaboração de informativos e instruções de trabalho
                                    </p>
                    
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Services;