import React from 'react';
import NavBar from '../components/Navbar/NavBar';

const Hero = () => {
    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-2 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <br></br>
                    <br></br>
                    <div id='hero' className="flex flex-col lg:flex-row py-10 justify-between text-center lg:text-left">
                            <h1 className="mb-0 md:text-3xl text-3xl font-bold text-[#227d93]">
                            <div className="text-xl font-semibold tracking-tight mb-1 text-gray-500">
                                Adaptamos as soluções ergonômicas às necessidades específicas de cada cliente. Com uma equipe multidisciplinar composta por especialistas em ergonomia, Engenharia e saúde ocupacional garantimos um serviço de alta qualidade e resultados duradouros. Nosso compromisso com a inovação, excelência e sustentabilidade nos torna a escolha ideal para empresas que buscam um parceiro confiável na promoção da saúde e segurança no trabalho.
                            </div>
                            </h1>
                     </div>
                </div>
            </div>
        </>
    )
}

export default Hero;